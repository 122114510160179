import { useRef, useEffect, useState } from 'react'
import cx from 'classnames'
import { Button } from '@blueprintjs/core'

const PlayerTextInstructions = ({ step = {} }) => {
  const [showMore, setShowMore] = useState(false)
  const [buttonVisible, setButtonVisible] = useState(false)
  const textNode = useRef()
  useEffect(() => {
    const el = textNode.current
    // true when text ellipsis
    if (el) {
      console.log(el, el.scrollHeight, el.offsetHeight)
      setButtonVisible(el.scrollHeight > el.offsetHeight)
    }
  }, [step.text])
  return (
    <>
      {step.text && (
        <>
          <div className={cx('detailed-instructions', { visible: showMore })}>
            {step.text}
            <div className="actions">
              <Button intent="primary" onClick={() => setShowMore(false)}>
                Close
              </Button>
            </div>
          </div>
          <div className="player-text-instructions">
            <p ref={textNode}>{step.text}</p>
            <button
              className={cx('read-more', { visible: buttonVisible })}
              onClick={() => setShowMore(true)}
            >
              Read more
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default PlayerTextInstructions
