import { toJS, makeAutoObservable, autorun, when, action } from 'mobx'
import { Iot } from 'aws-sdk'
import {
  initializePubSub,
  subscribe,
  unsubscribe,
  captureTopic,
  onConnection,
  onDisconnection,
} from '~/src/features/backend/pubsub'
import { PubSub } from '@aws-amplify/pubsub'
import { v4 as uuidv4 } from 'uuid'
import { timestamp } from './utils'
import { isEqual, cloneDeep } from 'lodash'
import { readParam, writeParam } from '~/src/utils/url'
import toaster from '~/src/features/toaster/toaster'
import CustomIcon from '~/src/app/components/Icon'

const DISCONNECTION_THRESHOLD = 10000
const HEARTBEAT_INTERVAL = 4000

export class MultiuserStore {
  clientId = uuidv4()
  peerHeartbeats = {}
  moderatorReactionDisposer = undefined
  needsRefresh = 0
  isModerator = false
  initialized = false
  ready = false

  // room
  roomToken = undefined
  moderationStart = undefined

  constructor(rootStore) {
    this.rootStore = rootStore
    window['multiuser'] = this
    makeAutoObservable(this)
  }

  get usersNum() {
    return this.clients.length
  }

  get clients() {
    const _refresh = this.needsRefresh
    const now = new Date().getTime()
    const clients = Object.values(this.peerHeartbeats).filter(
      h => now - h.timestamp < DISCONNECTION_THRESHOLD,
    )
    return clients
  }

  get moderator() {
    return this.clients.find(c => c.moderator === true)?.clientId
  }

  *initialize() {
    if (this.initialized) return
    this.initialized = true
    this.roomToken =
      readParam('session') || writeParam('session', this.makeRoomToken())

    yield initializePubSub(
      'wss://a37b2a2bwe1f7w-ats.iot.eu-central-1.amazonaws.com/mqtt',
    )

    onConnection(() => {
      toaster.show({
        icon: <CustomIcon icon="checkCircle" />,
        message: 'You have entered a session',
        timeout: 2500,
        className: 'player-toast',
      })
      this.ready = true
    })

    onDisconnection(() => {
      toaster.show({
        icon: <CustomIcon icon="crossCircle" />,
        intent: 'danger',
        className: 'player-toast',
        message: 'You have been disconnected. Try refreshing the page',
        timeout: 2500,
      })
      this.ready = false
    })

    window.onbeforeunload = ev => {
      this.send('update/disconnection')
    }

    setTimeout(() => this.sendHeartbeat(), 500)
    yield this.askForHeartbeats()
    yield this.subscribe('request/heartbeat', this.onHeartbeatRequest)
    yield this.subscribe('update/heartbeat', this.onHeartbeatUpdate)
    yield this.subscribe('update/disconnection', this.onDisconnectionUpdate)
    yield this.subscribe('update/moderator-state', this.onModeratorState)
    yield this.subscribe(
      'request/moderator-state',
      this.onModeratorStateRequest,
    )
    yield this.subscribe(
      'request/moderation-stop',
      this.onModerationStopRequest,
    )
    setInterval(this.sendHeartbeat, HEARTBEAT_INTERVAL)
    setInterval(
      action(() => this.needsRefresh++),
      HEARTBEAT_INTERVAL,
    )
    when(() => this.moderator, this.onStartModeration)
  }

  async subscribe(topic, callback, retryInterval = 1000) {
    const prefixedTopic = `${topic}-${this.roomToken}`
    await subscribe(prefixedTopic, callback, retryInterval)
  }

  async unsubscribe(topic) {
    const prefixedTopic = `${topic}-${this.roomToken}`
    await unsubscribe(prefixedTopic)
  }

  async captureTopic(topic, window, subscribeToTopic = true) {
    const prefixedTopic = `${topic}-${this.roomToken}`
    return await captureTopic(prefixedTopic, window, subscribeToTopic)
  }

  async send(topic, data = {}) {
    // console.log('!2 sent', topic, timestamp(), data)
    const prefixedTopic = `${topic}-${this.roomToken}`
    await PubSub.publish(prefixedTopic, { clientId: this.clientId, ...data })
  }

  sendHeartbeat = async () => {
    await this.send('update/heartbeat', {
      moderator: this.isModerator,
    })
  }

  sendState = async () => {
    this.send('update/moderator-state', {
      selectedStepId: this.rootStore.appState.selectedStepId,
      cameraPose: this.rootStore.sceneManager.cameraPose,
      moderationStart: this.moderationStart,
    })
  }

  async askForHeartbeats() {
    this.send('request/heartbeat')
    const heartbeats = await this.captureTopic('update/heartbeat', 1000)
    heartbeats.forEach(this.onHeartbeatUpdate)
  }

  async startModeration() {
    this.isModerator = true
    this.moderationStart = new Date().getTime()
    this.sendHeartbeat()
    toaster.show({
      icon: <CustomIcon icon="usersMore" />,
      message: 'Your audience is following you',
      timeout: 2500,
      className: 'player-toast',
    })
  }

  async stopModeration() {
    if (this.isModerator) {
      this.isModerator = false
      this.sendHeartbeat()
      this.moderatorReactionDisposer()
      toaster.show({
        icon: <CustomIcon icon="usersMore" />,
        message: 'Your audience is no longer following you',
        timeout: 2500,
        className: 'player-toast',
      })
    } else {
      this.send('request/moderation-stop')
    }
  }

  // --- listeners

  onStartModeration = data => {
    if (this.isModerator && this.moderator !== this.clientId)
      this.stopModeration()
    if (this.isModerator) {
      this.moderatorReactionDisposer = autorun(() => {
        this.sendState()
      })
    } else {
      this.send('request/moderator-state')
      toaster.show({
        icon: <CustomIcon icon="userCircle" />,
        message: 'Another user is now guiding your view',
        timeout: 2500,
        className: 'player-toast',
      })
    }
    const currentModerator = this.moderator
    when(
      () => this.moderator === undefined,
      () => this.onStopModeration(currentModerator),
    )
  }

  onStopModeration = lastModerator => {
    if (this.isModerator) {
      this.isModerator = false // this means we lost connection
    }
    if (lastModerator !== this.clientId) {
      toaster.show({
        icon: <CustomIcon icon="userCircle" />,
        message: 'The moderator is no longer guiding your view',
        timeout: 2500,
        className: 'player-toast',
      })
    }
    when(() => this.moderator, this.onStartModeration)
  }

  onModeratorState = data => {
    if (data.clientId !== this.clientId) {
      this.rootStore.appState.selectedStepId = data.selectedStepId
      this.moderationStart = data.moderationStart
      if (
        !isEqual(
          cloneDeep(this.rootStore.sceneManager.cameraPose),
          data.cameraPose,
        )
      ) {
        setTimeout(
          action(() => {
            const { position, target } = data.cameraPose
            this.rootStore.camera.startTransition(position, target)
          }),
        )
      }
    }
  }

  onModeratorStateRequest = data => {
    if (this.isModerator) this.sendState()
  }

  onModerationStopRequest = () => {
    if (this.isModerator) {
      this.stopModeration()
      toaster.show({
        icon: <CustomIcon icon="userCircle" />,
        message: 'Another user stopped the live moderation',
        timeout: 2500,
        className: 'player-toast',
      })
    }
  }

  onHeartbeatRequest = () => {
    this.sendHeartbeat()
  }

  onHeartbeatUpdate = data => {
    this.peerHeartbeats[data.clientId] = {
      ...data,
      timestamp: new Date().getTime(),
    }
  }

  onDisconnectionUpdate = data => {
    delete this.peerHeartbeats[data.clientId]
  }

  // --- utils

  makeRoomToken() {
    return Math.random().toString(36).substring(7)
  }
}

export default MultiuserStore
