import cx from 'classnames'
import IconHome from '~/assets/icons/home-1.svg'
import IconSettings from '~/assets/icons/filters-2.svg'
import IconSave from '~/assets/icons/save.svg'
import IconAdd from '~/assets/icons/plus-circle.svg'
import IconView from '~/assets/icons/eye-open.svg'
import IconTrash from '~/assets/icons/trash-2.svg'
import IconAudio from '~/assets/icons/speaker-2.svg'
import IconMicrophone from '~/assets/icons/microphone.svg'
import IconUpload from '~/assets/icons/upload.svg'
import IconPlay from '~/assets/icons/play.svg'
import IconStop from '~/assets/icons/stop.svg'
import IconCamera from '~/assets/icons/camera.svg'
import IconCameraDisabled from '~/assets/icons/camera-disabled.svg'
import IconPlusCircle from '~/assets/icons/plus-circle.svg'
import IconPlus from '~/assets/icons/plus.svg'
import IconSearch from '~/assets/icons/search.svg'
import IconSun from '~/assets/icons/sun.svg'
import IconDocumentCheck from '~/assets/icons/document-check.svg'
import IconDocumentMinus from '~/assets/icons/document-minus.svg'
import IconWarning from '~/assets/icons/warning-circle.svg'
import IconBlock from '~/assets/icons/block-2.svg'
import IconTag from '~/assets/icons/bp-tag.svg'
import IconMove from '~/assets/icons/bp-move.svg'
import IconMax from '~/assets/icons/bp-maximize.svg'
import IconRefresh from '~/assets/icons/bp-refresh.svg'
import IconDoubleCaret from '~/assets/icons/bp-double-caret.svg'
import IconArrowRight from '~/assets/icons/arrow-right.svg'
import IconArrowLeft from '~/assets/icons/arrow-left.svg'
import IconChevronRight from '~/assets/icons/chevron-right.svg'
import IconChevronLeft from '~/assets/icons/chevron-left.svg'
import IconAucta from '~/assets/images/aucta-logo.svg'
import IconPresentation from '~/assets/icons/presentation.svg'
import IconUser from '~/assets/icons/user-1.svg'
import IconUsers from '~/assets/icons/users.svg'
import IconUserCircle from '~/assets/icons/user-2.svg'
import IconUsersMore from '~/assets/icons/users-more.svg'
import IconCrossCircle from '~/assets/icons/cross-circle.svg'
import IconCheckCircle from '~/assets/icons/check-circle.svg'
import IconInfoCircle from '~/assets/icons/info-circle.svg'
import IconCopy from '~/assets/icons/copy.svg'

const icons = {
  home: IconHome,
  settings: IconSettings,
  save: IconSave,
  add: IconAdd,
  view: IconView,
  trash: IconTrash,
  audio: IconAudio,
  microphone: IconMicrophone,
  upload: IconUpload,
  play: IconPlay,
  stop: IconStop,
  camera: IconCamera,
  cameraDisabled: IconCameraDisabled,
  plusCircle: IconPlusCircle,
  plus: IconPlus,
  search: IconSearch,
  sun: IconSun,
  documentCheck: IconDocumentCheck,
  documentMinus: IconDocumentMinus,
  warning: IconWarning,
  block: IconBlock,
  tag: IconTag,
  refresh: IconRefresh,
  max: IconMax,
  move: IconMove,
  doubleCaret: IconDoubleCaret,
  arrowRight: IconArrowRight,
  arrowLeft: IconArrowLeft,
  chevronRight: IconChevronRight,
  chevronLeft: IconChevronLeft,
  aucta: IconAucta,
  presentation: IconPresentation,
  user: IconUser,
  users: IconUsers,
  userCircle: IconUserCircle,
  usersMore: IconUsersMore,
  crossCircle: IconCrossCircle,
  checkCircle: IconCheckCircle,
  infoCircle: IconInfoCircle,
  copy: IconCopy,
}

const Icon = ({ icon, className }) => {
  const Icon = icons[icon]
  if (!Icon) return icon
  else return <Icon className={cx('custom-icon', className)} />
}

export default Icon
