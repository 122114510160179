import { observer } from 'mobx-react-lite'
import { useState, useEffect } from 'react'
import { Button } from '@blueprintjs/core'
import CustomIcon from '~/src/app/components/Icon'
import cx from 'classnames'
import toaster from '~/src/features/toaster/toaster'
import CustomIcon from '~/src/app/components/Icon'
import Tooltip from '~/src/utils/components/Tooltip'

export function MultiuserPanel({
  ready,
  roomToken,
  users,
  moderator,
  isModerator,
  moderationStart,
  onModeration,
  onStopModeration,
}) {
  const [elapsed, setElapsed] = useState(0)
  const [isPristine, setPristine] = useState(true)

  useEffect(() => {
    if (isPristine && (moderator || isModerator)) setPristine(false)
  }, [moderationStart])

  useEffect(() => {
    if (!moderator) return
    const interval = setInterval(() => {
      if (moderationStart)
        setElapsed((new Date().getTime() - moderationStart) / 1000)
    }, 1000)
    return () => {
      clearInterval(interval)
      setElapsed(0)
    }
  }, [moderationStart, moderator])

  const seconds = pad(Math.floor(elapsed % 60), 2)
  const minutes = pad(Math.floor(elapsed / 60), 2)

  return (
    <div
      className={cx('multiuser-panel-wrapper', {
        'is-disabled': !ready,
      })}
    >
      <div className={cx('multiuser-panel', { 'has-moderator': !!moderator })}>
        <div className="multiuser-panel-main">
          <div className="moderator">
            <p className="moderator-title">Moderation</p>
            {isModerator ? <p className="moderator-name">You</p> : null}
          </div>
          <div className="timer">
            {minutes}:{seconds}
          </div>
          <button onClick={onStopModeration} className="stop-button">
            <CustomIcon icon="presentation" />
          </button>
        </div>
        <button
          onClick={onModeration}
          className={cx('start-button', {
            'start-moderation': !!moderator,
            'end-moderation': !isPristine && !moderator && !isModerator,
          })}
        >
          <CustomIcon icon="presentation" />
          <span className="text">Start Live Moderation</span>
        </button>
      </div>
      <Tooltip content="Copy session URL to clipboard" placement="bottom">
        <Button
          onClick={() => copySessionLinkToClipboard(roomToken)}
          className="top-button users-button"
          icon={<CustomIcon icon="user" />}
          large
        >
          <span className="super-index">{users}</span>
        </Button>
      </Tooltip>
    </div>
  )
}

function pad(num, size) {
  return ('000' + num).slice(size * -1)
}

// --- utils

function copySessionLinkToClipboard(roomToken) {
  navigator.clipboard.writeText(generateSessionURL(roomToken)).then(() => {
    toaster.show({
      icon: <CustomIcon icon="copy" />,
      message: 'Session URL copied',
      timeout: 2500,
      className: 'player-toast',
    })
  })
}

export function generateSessionURL(roomToken) {
  const urlParams = new URLSearchParams(window.location.search)
  urlParams.set('session', roomToken)
  urlParams.set('mute', 'true')
  const protocol = isDebugMode() ? 'http' : 'https'
  return `${protocol}://${window.location.host}${
    window.location.pathname
  }?${urlParams.toString()}${window.location.hash}`
}

function isDebugMode() {
  return location.hostname === 'localhost' || location.hostname === '127.0.0.1'
}

export default observer(MultiuserPanel)
