export function onStepLoaded(idx, total) {
  if (window.parent === window) return
  const iFrameEvent = {
    type: 'step-loaded',
    payload: {
      stepIdx: idx + 1,
      totalSteps: total,
    },
  }
  window.parent.postMessage(iFrameEvent, '*')
}

export function onInstructionFinished() {
  if (window.parent === window) return
  const iFrameEvent = {
    type: 'instruction-end',
  }
  window.parent.postMessage(iFrameEvent, '*')
}

export function onInstructionStarted() {
  if (window.parent === window) return
  const iFrameEvent = {
    type: 'instruction-start',
  }
  window.parent.postMessage(iFrameEvent, '*')
}
