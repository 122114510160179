import { useStore } from '~/src/app/store'
import { Menu, MenuItem } from '@blueprintjs/core'

const PlayerMenu = () => {
  const { appState } = useStore()
  return (
    <div className="player-menu">
      <Menu>
        <MenuItem
          icon="fullscreen"
          text="Fullscreen"
          onClick={() => {
            appState.enableFullscreen()
          }}
        />
      </Menu>
    </div>
  )
}

export default PlayerMenu
