import { Spinner, Icon } from '@blueprintjs/core'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useStore } from '~/src/app/store'
import { useEffectAfterMount } from '~/src/hooks/general'

const SaveStatusDisplay = () => {
  const { persistence } = useStore()

  const [visible, setVisible] = useState(false)
  const [timeout, _setTimeout] = useState()
  const state = persistence.savingState

  useEffectAfterMount(() => {
    setVisible(true)
    if (state === 'saving') clearTimeout(timeout)
    if (state !== 'saving') {
      _setTimeout(
        setTimeout(() => {
          setVisible(false)
        }, 3000),
      )
    }
    return () => clearTimeout(timeout)
  }, [state])

  return (
    visible && (
      <div className="save-status-display">
        <span className="save-status-text">{state}</span>
        {state === 'saving' ? (
          <Spinner intent="primary" size={17} />
        ) : state === 'saved' ? (
          <Icon icon="tick" />
        ) : (
          <Icon icon="crossCircle" />
        )}
      </div>
    )
  )
}

export default observer(SaveStatusDisplay)
