import React, { useState, useRef, useEffect } from 'react'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Card, Button, Spinner } from '@blueprintjs/core'
import {
  useStep,
  useStepCount,
  useSelectedStepId,
  useRemoveStep,
  useMoveStep,
  useSaveCamera,
  useRemoveCamera,
} from '~/src/hooks/training'
import { useStore } from '~/src/app/store'
import { isElementInViewport } from '~/src/utils/dom'
import cx from 'classnames'
import CustomIcon from '~/src/app/components/Icon'
import Tooltip from '../../utils/components/Tooltip'
import DeleteStepAlert from './DeleteStepAlert'

const StepThumbnail = ({ stepId }) => {
  const { appState } = useStore()
  const [stepToDelete, setStepToDelete] = useState(false)
  const [selectedStepId, setSelectedStepId] = useSelectedStepId()
  const isSelected = selectedStepId === stepId
  const canRemoveStep = useStepCount() > 1
  const removeStep = useRemoveStep()
  const step = useStep(stepId)
  const confirmDeleteStep = action(() => {
    removeStep(stepToDelete)
    setStepToDelete(false)
  })
  const cancelDeleteStep = action(() => {
    setStepToDelete(false)
  })

  // drag & drop
  const [isDragging, setDragging] = useState(false)
  const [isDropping, setDropping] = useState(false)
  const [isDropHigh, setDropHigh] = useState(false)
  const moveStep = useMoveStep()
  const cardRef = useRef()
  const handleDragStart = e => {
    const img = new Image()
    e.dataTransfer.setDragImage(img, 10, 10)
    e.dataTransfer.effectAllowed = 'move'
    setDragging(true)
    appState.draggingStepId = step.id
  }

  const handleDragEnd = () => {
    setDragging(false)
    appState.draggingStepId = null
  }

  const handleDragEnter = () => {
    setDropping(true)
    console.log('> drag enter!')
  }

  const handleDragOver = e => {
    e.preventDefault()
    const { pageY } = e
    const { y, height } = cardRef.current.getBoundingClientRect()
    const isHigh = pageY <= y + height / 2
    setDropHigh(isHigh)
  }

  const handleDragLeave = () => {
    setDropping(false)
  }

  const handleDrop = e => {
    moveStep(appState.draggingStepId, stepId, isDropHigh)
    setDropping(false)
    e.preventDefault()
  }

  const saveCamera = useSaveCamera(step)
  const removeCamera = useRemoveCamera(step)

  useEffect(() => {
    if (isSelected && !isElementInViewport(cardRef.current)) {
      cardRef.current.scrollIntoView()
    }
  }, [selectedStepId, stepId, cardRef])

  return (
    <>
      <DeleteStepAlert
        stepToDelete={stepToDelete}
        confirmDeleteStep={confirmDeleteStep}
        cancelDeleteStep={cancelDeleteStep}
      />

      <div
        className="drag-drop-box"
        draggable
        ref={cardRef}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <Card
          className={cx('step-thumbnail', {
            selected: isSelected,
            dragging: isDragging,
          })}
          onClick={() => setSelectedStepId(stepId)}
        >
          {step.thumbnail && (
            <Spinner
              className="background-spinner"
              intent="primary"
              size={36}
            />
          )}
          {step.thumbnail && <img src={step.thumbnail} />}
          <div
            className={cx('drop-overlay', {
              dragging: isDragging,
              dropping: isDropping,
              'drop-high': isDropHigh,
              'drop-low': !isDropHigh,
            })}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          />
          {canRemoveStep && (
            <div className="delete-button-wrapper">
              <Tooltip content="Delete step" placement="bottom">
                <Button
                  className="delete-button"
                  minimal
                  icon="cross"
                  onClick={e => {
                    e.stopPropagation()
                    setStepToDelete(stepId)
                  }}
                />
              </Tooltip>
            </div>
          )}
          <div
            className={cx('thumbnail-actions', {
              'no-pointer': !!appState.draggingStepId,
            })}
          >
            {step.camera && (
              <CustomIcon icon="camera" className="camera-saved-indicator" />
            )}
            {isSelected && (
              <>
                {step.camera && (
                  <Tooltip content="Remove camera position" placement="top">
                    <Button
                      className="camera-button is-disabled"
                      minimal
                      icon={<CustomIcon icon="cameraDisabled" />}
                      onClick={removeCamera}
                    />
                  </Tooltip>
                )}
                <Tooltip content="Save camera position" placement="top">
                  <Button
                    className={cx('camera-button', {
                      'camera-saved': step.camera,
                    })}
                    minimal
                    icon={<CustomIcon icon="camera" />}
                    onClick={saveCamera}
                  />
                </Tooltip>
              </>
            )}
          </div>
        </Card>
      </div>
    </>
  )
}

export default observer(StepThumbnail)
