import unauthConfig from '../config/aws-exports-unauth'
import Auth from '@aws-amplify/auth'
import { config } from 'aws-sdk/global'
import Iot from 'aws-sdk/clients/iot'
import { AWSIoTProvider, PubSub } from '@aws-amplify/pubsub'
import { wait, timestamp } from '../utils/time'
import { v4 as uuidv4 } from 'uuid'

const captured = {}
const subscriptions = {}
let disconnectionCallback
let connectionCallback
let connectionNotified = false
let disconnectionNotified = false
let currentEndpoint

export async function initializePubSub(endpoint) {
  currentEndpoint = endpoint
  const currentCredentials = await Auth.currentCredentials()
  try {
    const currentUser = await Auth.currentAuthenticatedUser()
    if (currentUser) {
      await attachIOTPolicy(currentCredentials.identityId)
    }
  } catch (err) {
    console.error('Error initializing PubSub:', err)
  }

  config.update({ ...unauthConfig })
  PubSub.addPluggable(
    new AWSIoTProvider({
      aws_pubsub_region: 'eu-central-1',
      aws_pubsub_endpoint: endpoint,
    }),
  )
}

export async function attachIOTPolicy(identityId) {
  const iot = new Iot({
    region: config.region,
    apiVersion: '2015-05-28',
  })

  const response = await iot
    .attachPolicy({
      policyName: 'IOTPubSub',
      target: identityId,
    })
    .promise()
}

export async function captureTopic(topic, window, subscribeToTopic = true) {
  if (subscribeToTopic) subscribe(topic)
  captured[topic] = []
  await wait(window)
  const result = captured[topic]
  if (subscribeToTopic) unsubscribe(topic)
  delete captured[topic]
  return result
}

export async function subscribe(topic, callback, retryInterval = 1000) {
  subscriptions[topic] = PubSub.subscribe(topic).subscribe({
    next: data => {
      notifyConnection()
      // console.log(`Received in ${topic}:`, timestamp(), data.value)
      if (captured[topic] !== undefined) {
        captured[topic].push(data.value)
      }
      if (callback) callback(data.value)
    },
    error: error => {
      notifyDisconnection()
      console.error(`Error in ${topic}: `, error)
      setTimeout(
        async () => {
          await retryConnection()
          subscribe(topic, callback, retryInterval * 2)
        },

        retryInterval,
      )
    },
  })
}

export async function retryConnection() {
  console.log('Trying to reconnect...')
  // :/
  PubSub._pluggables[0] = new AWSIoTProvider({
    aws_pubsub_region: 'eu-central-1',
    aws_pubsub_endpoint: currentEndpoint,
  })
}

export async function onDisconnection(callback) {
  disconnectionCallback = callback
}

export async function onConnection(callback) {
  connectionCallback = callback
  const token = uuidv4()
  await subscribe(token, notifyConnection)
  setTimeout(() => {
    PubSub.publish(token, { msg: 'OK' })
  }, 1000)
}

function notifyConnection() {
  if (connectionCallback && !connectionNotified) {
    connectionCallback()
    connectionNotified = true
    disconnectionNotified = false
  }
}

function notifyDisconnection() {
  if (disconnectionCallback && !disconnectionNotified) {
    disconnectionCallback()
    disconnectionNotified = true
    connectionNotified = false
  }
}

export async function unsubscribe(topic) {
  await subscriptions[topic].unsubscribe()
  delete subscriptions[topic]
}
