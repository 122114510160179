const params = new URLSearchParams(window.location.search)

export function readParam(key) {
  return params.get(key)
}

export function readBoolParam(key) {
  const value = params.get(key)
  return value !== 'false' && value !== null && value !== undefined
}

export function writeParam(key, value) {
  const urlParams = new URLSearchParams(window.location.search)
  if (!value) {
    urlParams.delete(key)
  } else {
    urlParams.set(key, value)
  }
  const newLocation =
    window.location.pathname + '?' + urlParams.toString() + window.location.hash
  history.replaceState(null, '', newLocation)
  return value
}
