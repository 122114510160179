import { toJS, makeAutoObservable, autorun } from 'mobx'
import { v4 as uuid } from 'uuid'
import Step from './step'
import {
  get,
  pull,
  isEmpty,
  assign,
  mapValues,
  method,
  defaults,
  values,
} from 'lodash'

class Training {
  id = uuid()
  version = 'v3'
  thumbnail = null
  hasCustomThumbnail = false
  autoPlay = false
  title = ''
  description = ''
  transitionDuration = 500
  stepSequence = []
  steps = {}

  constructor() {
    makeAutoObservable(this, {})
    // training always has at least one step
    this.addStep(new Step())
    autorun(() => {
      if (!this.hasCustomThumbnail) this.thumbnail = this.firstStep.thumbnail
    })
  }

  findStep = id => {
    return this.steps[id]
  }

  get firstStep() {
    return get(this.steps, this.stepSequence[0], {})
  }

  findStepByIdx = idx => {
    const id = this.stepSequence[idx]
    return this.findStep(id)
  }

  removeStep(id) {
    pull(this.stepSequence, id)
    delete this.steps[id]
  }

  createFirstStep() {
    const step = new Step()
    this.steps[step.id] = step
  }

  cloneStep(sourceStepId) {
    const refStep = this.findStep(sourceStepId)
    if (!refStep) throw Error(`Unable to clone step: ${sourceStepId}`)
    const step = Step.clone(refStep)
    return this.addStep(step)
  }

  addStep(step) {
    this.steps[step.id] = step
    this.stepSequence.push(step.id)
    return step
  }

  getAllSteps() {
    return values(this.steps)
  }

  getTotalSteps() {
    return this.stepSequence.length
  }

  getStepIndex(stepId) {
    return this.stepSequence.findIndex(step => step === stepId)
  }

  getStepCamera = stepId => {
    const step = this.findStep(stepId)
    if (step.camera) return step.camera
    const currentIndex = this.getStepIndex(stepId)
    for (let i = currentIndex; i > -1; i--) {
      const stepCamera = this.findStepByIdx(i).camera
      if (stepCamera) return stepCamera
    }
    return undefined
  }

  dispose() {
    this.readJSON({})
  }

  toJSON() {
    const json = toJS(this)
    return {
      id: json.id,
      version: json.version,
      thumbnail: json.thumbnail,
      title: json.title,
      description: json.description,
      autoPlay: json.autoPlay,
      transitionDuration: json.transitionDuration,
      steps: mapValues(this.steps, method('toJSON')),
      stepSequence: json.stepSequence,
    }
  }

  readJSON(json) {
    const data = defaults(json, {
      id: uuid(),
      version: 'v3',
      steps: {},
      stepSequence: [],
      transitionDuration: 500,
      hasCustomThumbnail: false,
      disableMultiuser: false,
      thumbnail:
        'https://content.aucta.io/media/default-training-thumbnail.png',
      title: 'New training',
      description: 'Training created from editor',
      autoPlay: false,
    })
    assign(this, {
      id: data.id,
      version: data.version,
      hasCustomThumbnail: data.hasCustomThumbnail,
      thumbnail: data.thumbnail,
      disableMultiuser: data.disableMultiuser,
      title: data.title,
      description: data.description,
      autoPlay: data.autoPlay,
      transitionDuration: data.transitionDuration,
      stepSequence: data.stepSequence,
      steps: mapValues(data.steps, data => Step.fromJSON(data)),
    })
    // make sure the 'main' track always exists
    if (isEmpty(this.stepSequence)) {
      this.addStep(new Step())
    }
  }
}

export default Training
