import { makeAutoObservable, flow, reaction } from 'mobx'
import { wait } from '~/src/utils/general'
import { assign, indexOf } from 'lodash'
import rootStore from '~/src/app/store'
import {
  onStepLoaded,
  onInstructionStarted,
  onInstructionFinished,
} from '~/src/utils/iFrameCommunication'
import { readBoolParam } from '~/src/utils/url'

class PlayerStore {
  autoPlay = false
  disabledFeatures = {
    audio: readBoolParam('disable-audio'),
    audioAutoplay: readBoolParam('mute'),
    multiuser: readBoolParam('disable-multiuser'),
    markers: readBoolParam('disable-markers'),
    vr: readBoolParam('disable-vr'),
    settings: readBoolParam('disable-settings'),
  }

  constructor({ appState, training }) {
    makeAutoObservable(this, {})
    assign(this, { appState, training })
  }

  start() {
    reaction(
      () => this.training.id,
      () => {
        if (this.training.disableMultiuser)
          this.disabledFeatures.multiuser = true
        if (!this.disabledFeatures.multiuser) {
          rootStore.multiuser.initialize()
        }
      },
    )
    this.autoPlay = this.training.autoPlay
    this.maybePlayNextStep()
    onInstructionStarted()
  }

  getCurrentStepInfo() {
    const { appState, training } = this
    const idx = indexOf(training.stepSequence, appState.selectedStepId)
    const total = training.stepSequence.length
    return { idx, total }
  }

  manuallyNavigateToNextStep() {
    this.autoPlay = false
    this.navigateToNextStep()
  }

  navigateToNextStep() {
    const { appState, training } = this
    const { idx } = this.getCurrentStepInfo()
    rootStore.animator.stopActiveTransition()
    // rootStore.transitions.halt()
    appState.selectedStepId = training.stepSequence[idx + 1]
  }

  manuallyNavigateToPrevStep() {
    this.autoPlay = false
    this.navigateToPrevStep()
  }

  navigateToPrevStep() {
    const { appState, training } = this
    const { idx } = this.getCurrentStepInfo()
    rootStore.animator.stopActiveTransition()
    // rootStore.transitions.halt()
    appState.selectedStepId = training.stepSequence[idx - 1]
  }

  isForwardNavigationPossible() {
    const { idx, total } = this.getCurrentStepInfo()
    return idx < total - 1
  }

  isBackNavigationPossible() {
    const { idx } = this.getCurrentStepInfo()
    return idx > 0
  }

  maybePlayNextStep = flow(function* () {
    const { appState, training } = this
    if (
      appState.mode !== 'editor' &&
      this.autoPlay &&
      this.isForwardNavigationPossible()
    ) {
      yield wait(training.transitionDuration)
      // check again to see if it changed after the wait
      if (this.autoPlay) this.navigateToNextStep()
    } else {
      this.autoPlay = false
    }
  })

  onTransitionFinished() {
    const totalSteps = this.getCurrentStepInfo().total
    const currentStepIdx = this.getCurrentStepInfo().idx
    onStepLoaded(currentStepIdx, totalSteps)
    if (currentStepIdx === totalSteps - 1) onInstructionFinished()
    this.maybePlayNextStep()
  }
}

export default PlayerStore
