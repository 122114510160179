const envName =
  process.env['REACT_APP_ENV'] || process.env['NODE_ENV'] || 'development'

export const region = 'eu-central-1'

export const managerIdentityPoolId =
  'eu-central-1:0facab09-4f8f-4dd9-86ea-b0760a0b3f00'
export const managerIdentityPoolUnauthRole =
  'arn:aws:iam::396824024623:role/Cognito_AuctaManager_Unauth_Role'
export const configBucket = 'aucta-config'
export const userContentBucket = 'manager-user-content'

export default {
  region,
  identityPoolId: managerIdentityPoolId,
}
